<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import draggable from 'vuedraggable'

	export default {
		name: "BoRegion",
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "BoRegion",
				mrLevel: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
		},

		methods: {
			endDrag() {
				BOGen.apirest('/' + this.Name, {
					data: this.data.data,
					type: 'sort'
				}, (err, resp) => {
					console.log(resp)
				}, "POST");
			}
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row justify-content-between">
								<div class="col-md-5">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div class="col-md-6 col-lg-4">
									<div class="form-row">
										<div class="col-9">
											<div class="form-group mb-0">
												<div class="input-group">
													<input type="text" v-model="filter.search" class="form-control"
														placeholder="Search...">
													<div class="input-group-append">
														<button class="btn btn-info" type="button" @click="search()"><i
																class="fas fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										<div class="col-3">
											<router-link :to="{name:Name}" class="btn btn-warning btn-block">Reset</router-link>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="mr_name_en"></SortField>
									</th>
									<!-- <th>Status</th> -->
									<th width="120px">Action</th>
								</tr>
							</thead>
							<draggable v-model="data.data" tag="tbody" @end="endDrag" style="cursor:pointer;">
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.mr_name_en}}</td>
									<!-- <td><StatusLabel :status="v.status"></StatusLabel></td> -->
									<td class="btn-action">
										<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}"
											v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
										<!-- <a href="javascript:;" class="icon_action" @click="changeStatus(k)" v-tooltip="'Change Status'"><i class="icon-settings"></i></a> -->
										<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)"
											v-tooltip="'Remove'"><i class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</draggable>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-md-6">
							<BoField name="mr_name_en" v-model="row.mr_name_en"></BoField>
							<BoField name="mr_name_id" v-model="row.mr_name_id"></BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit"
									class="fcbtn btn btn-info btn-outline btn-1e btn-loading btn-rounded">Save
									Region</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>